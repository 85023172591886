import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

export default function Blog({ data, location }) {
  const { posts } = data.blog;
  const { coverImage } = data.blogJson;

  return (
    <Layout bodyClass="page-blog">
      <SEO title="Blog" image={coverImage} url={location.href} />

      <div className="blog pt-6">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <h1>Blog</h1>
              <div>
                <a href="/blog/rss.xml" title="RSS Feed">
                  <img width="32" src="/images/social/rss.svg" alt="RSS" />
                </a>
              </div>
            </div>
          </div>
          <div className="row pb-3">
            {coverImage && (
              <div className="col-12">
                <img
                  alt="Cover of the blog"
                  className="img-fluid"
                  src={coverImage}
                />
              </div>
            )}
          </div>
          <div className="row justify-content-start">
            <div className="col-12">
              {posts.map((post) => (
                <div className="pb-2">
                  <article key={post.id}>
                    <Link to={post.fields.slug}>
                      <h2>{post.frontmatter.title}</h2>
                    </Link>
                    <p>
                      {post.excerpt}
                      {'. '}
                      <Link to={post.fields.slug}>Zum Artikel</Link>
                    </p>
                  </article>
                  <hr width="100%" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query Blog {
    blog: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/.*/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(fromNow: true)
          title
          author
        }
        excerpt
        id
      }
    }
    blogJson {
      coverImage
    }
  }
`;
